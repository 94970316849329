<template>
    <v-alert
        :value="show"
        color="orange lighten-2">
        <v-row>
            <v-col>{{ $t('cartpay.minAmount') }}</v-col>
            <v-col class="shrink">{{ min | num(currency) }}</v-col>
        </v-row>

        <strong>{{ $t('cartpay.minAmountHelp', { amount: $options.filters.num(missing, currency) }) }}</strong>

        <v-input
            type="hidden"
            :rules="[$required]"
            :value="!show"
            single-line
            hide-details />
    </v-alert>
</template>

<script>
export default {
    name: 'acs-cart-min-amount',
    props: {
        min: { type: [String, Number] },
        total: { type: [String, Number] }
    },
    computed: {
        show() {
            return this.min > 0 && Math.round(this.missing * 100) / 100 > 0
        },

        missing() {
            return this.min - this.total
        },

        currency() {
            return this.$store.getters['sp/current'].currency_users
        }
    }
}
</script>
