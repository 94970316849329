<template>
    <acs-base-container large size="large">
        <v-card flat :loading="loading" class="acs-cart-pay--container">
            <v-form ref="form" v-model="valid" @submit.stop.prevent="() => null">
                <template v-if="step === 0">
                    <v-card-title class="text-h5">{{ $t('cartpay.summary') }}</v-card-title>

                    <v-divider class="mx-3" />
                    <v-simple-table dense>
                        <template #default>
                            <tbody>
                                <tr>
                                    <td>{{ $t('cartpay.total') }}</td>
                                    <td class="text-right">{{ amountProducts | num(currency) }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('cartpay.totaloptions') }}</td>
                                    <td class="text-right">{{ amountOptions | num(currency) }}</td>
                                </tr>
                                <tr v-for="(vat, i) in vats" :key="i">
                                    <td>{{ $t('cart.vat', { rate: vat.rate * 100 }) }}</td>
                                    <td class="text-right">{{ vat.amount | num(currency) }}</td>
                                </tr>
                                <tr class="text-subtitle-2">
                                    <td>{{ $t('cartpay.totalorder') }}</td>
                                    <td class="text-right">{{ amountWithVat | num(currency) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <acs-cart-min-amount
                        class="mx-3"
                        :total="amountWithVat + tip"
                        :min="orderMinAmount" />

                    <v-card-text>
                        <v-divider v-if="showTip" />
                        <acs-cart-tip
                            v-if="showTip"
                            v-model="tip"
                            @input="updateTip"
                            @waiting="waitTip"
                            debounce
                            class="my-2" />

                        <acs-cart-discount
                            v-if="showDiscount"
                            v-model="discount"
                            @input="updateDiscount"
                            @waiting="waitTip"
                            debounce
                            class="my-2" />

                        <v-divider />
                        <acs-cart-age
                            v-model="ageAccepted"
                            @update:max-age="age => ageMax = age"
                            v-show="ageMax > 0" />

                        <acs-pay-cg v-model="conditionsAccepted" />

                        <v-divider class="my-4" />
                        <acs-cart-scan
                            v-if="isSlotService"
                            class="mx-0 mb-3 mt-4"
                            :disabled="loading"
                            :slug="slug"
                            :aslot="aslot"
                            :label="$t('cartpay.slot')" />
                    </v-card-text>
                </template>
                <v-card-text>
                    <div class="d-flex justify-space-between text-h5">
                        <span>{{ $t('cartpay.pay') }}</span>
                        <span>{{ total | num(currency) }}</span>
                    </div>
                    <acs-pay-connect v-show="!connected" />
                </v-card-text>
                <acs-cart-pay-process
                    type="cart"
                    ref="payProcess"
                    :step.sync="step"
                    :disabled="!valid"
                    :slug="slug"
                    :aslot="aslot"
                    :direct="total === 0" />

                <v-card-actions class="px-3" v-if="step">
                    <v-btn class="mt-5" block outlined rounded @click="step = 0">Retour</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsCartTip from '@/components/cart/AcsCartTip'
import AcsCartDiscount from '@/components/cart/AcsCartDiscount'
import AcsCartAge from '@/components/cart/AcsCartAge'
import AcsCartMinAmount from '@/components/cart/AcsCartMinAmount'
import AcsCartScan from '@/components/cart/AcsCartScan'
import AcsCartPayProcess from '@/components/cart/AcsCartPayProcess'
import AcsPayCg from '@/components/pay/AcsPayCg'
import AcsPayConnect from '@/components/pay/AcsPayConnect'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'cart-pay',
    components: {
        AcsBaseContainer,
        AcsCartTip,
        AcsCartDiscount,
        AcsCartAge,
        AcsCartMinAmount,
        AcsCartScan,
        AcsCartPayProcess,
        AcsPayCg,
        AcsPayConnect
    },
    props: {
        slug: { type: String, required: true },
        aslot: { type: String, required: false }
    },
    mixins: [RouteMixin],
    data: () => ({
        step: 0,
        valid: false,
        loading: false,
        totals: null,
        showTip: false,
        tip: null,
        discount: null,
        ageMax: 0,
        ageAccepted: false,
        conditionsAccepted: false
    }),
    computed: {
        products() {
            return this.$store.getters['cart/products']
        },

        cart() {
            return this.$store.getters['cart/cart'] || {}
        },

        salepoint() {
            return this.$store.getters['sp/current']
        },

        currency() {
            return this.salepoint.currency_users
        },

        vats() {
            return this.totals?.filter(total => total.type === 'tax') || []
        },

        amountNoVat() {
            return this.totals?.find(total => total.type === 'totalWithoutTax')?.amount || 0
        },

        amountProducts() {
            return this.totals?.find(total => total.type === 'totalProductWithoutTax')?.amount || 0
        },

        amountOptions() {
            return this.totals?.find(total => total.type === 'totalOptionWithoutTax')?.amount || 0
        },

        amountWithVat() {
            return this.totals?.find(total => total.type === 'totalWithTax')?.amount || 0
        },

        total() {
            return this.totals?.find(total => total.type === 'total')?.amount || 0
        },

        service() {
            return this.$store.getters['ser/services'].find(s => s.id === this.cart.service_id)
        },

        orderMinAmount() {
            return Math.max(
                this.salepoint.order_min_amount || 0,
                this.service?.min_order_amount || 0
            )
        },

        isSlotService() {
            return this.service?.type === 'slot'
        },

        showDiscount() {
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'team')
        },

        connected() {
            return this.$store.getters['auth/connected']
        }
    },
    watch: {
        connected(v) {
            this.conditionsAccepted = v
        }
    },
    mounted() {
        if (this.connected) {
            this.conditionsAccepted = true
        }
        this.load()
    },
    methods: {
        load() {
            this.loading = false
            return Promise
                .all([
                    this.$store.dispatch('cart/get', { slug: this.slug }),
                    this.$store.dispatch('cart/getAmountsByTax', { slug: this.slug }),
                    this.$store.dispatch('sp/current', { slug: this.slug }),
                    this.$store.dispatch('ser/services', { slug: this.slug }),
                    this.aslot && this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
                ])
                .then(async res => {
                    if (!res[0] || !res[0].products.length) {
                        return this.$router.push({ name: this.getContextRouteName('cart') })
                    }

                    // #ACC-817: on set l'ID du panier en localstorage
                    this.$store.commit('cart/setCurrentCartId', { id: this.cart.id })

                    this.totals = res[1]
                    this.tip = res[0].tip
                    this.discount = res[0].discount

                    await this.$store.dispatch('dbg/log', {
                        slug: this.slug,
                        cid: this.cart.id,
                        msg: 'fetch cart totals',
                        products: this.products.length,
                        total: this.total
                    })

                    // redirection à l'intérieur du composant s'il s'agit d'un retour d'un setupintent Stripe
                    if (this.$route.query?.setup_intent) {
                        this.conditionsAccepted = true
                    }
                    this.showTip = this.service?.accept_payment && this.service?.accept_tip
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        waitTip() {
            this.loading = true
            return this.$refs.payProcess.reset(true)
        },

        updateTip() {
            this.loading = true
            return this.$store
                .dispatch('cart/update', {
                    slug: this.slug,
                    tip: this.tip
                })
                .then(() => this.$store.dispatch('cart/getAmountsByTax', { slug: this.slug }))
                .then(res => {
                    this.totals = res
                    return this.$refs.payProcess.autoReloadPsp()
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        updateDiscount() {
            this.loading = true
            return this.$store
                .dispatch('cart/updateDiscount', {
                    slug: this.slug,
                    discount: this.discount
                })
                .then(() => this.$store.dispatch('cart/getAmountsByTax', { slug: this.slug }))
                .then(res => (this.totals = res))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.acs-cart-pay--container
    td
        border-bottom: 0 !important
        border-top: 0 !important

</style>
